import React from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';

export default function Contact() {

  return (
    <>    
    <Container className="g-2 p-5">
    
      <h2 className="lg:text-xl sm:text-sm pt-3 text-center font-bold p-4">
        Contact: 
        <span class="before:block before:absolute before:-inset-1 before:-skew-y-3 before:bg-green-400 relative inline-block">
          <span class="relative text-white">Jamia Arabia Islamia </span>
        </span>
      </h2>

      <Form>
        <Row className="g-2">
        <Col>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>
        </Col>

        <Col>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" />
        </Form.Group>
        </Col>
    

        <Form.Select  as={Col} md="4" aria-label="Default select" className="mb-3">
          <option>Select an Item</option>
          <option value="1">One</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
        </Form.Select>
        
        <InputGroup className="mb-3">
          <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
          <Form.Control
            placeholder="Username"
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </InputGroup>

        <FloatingLabel  as={Col} md="8" 
          controlId="floatingInput"
          label="Email address"
          className="mb-3"
        >
        <Form.Control type="email" placeholder="name@example.com" />
        </FloatingLabel>

        <FloatingLabel  as={Col} md="4" controlId="floatingPassword" label="Password" className="mb-3">
          <Form.Control type="password" placeholder="Password" />
        </FloatingLabel>
        
        <FloatingLabel controlId="floatingTextarea2" label="Comments">
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: '100px' }}
          />
        </FloatingLabel>

        <Stack gap={2} className="col-md-5 mx-auto">
          <Button variant="secondary">Save changes</Button>
        </Stack>
        
        </Row>
      </Form>
    </Container>


    </>
  );

}
