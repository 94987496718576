import React, { useRef } from "react";
import { useInView } from "framer-motion";
import Box from "./Component/Box";

export default function FacultiesSection() {
  const data = [
    {
      title: "Tahfeez ul Quran",
      description: "Dive into the sacred world of Quranic memorization and recitation with our Tahfeez ul Quran faculty, where students embark on a profound journey of preserving the divine word in their hearts.",
      btnValue: "Learn more",
    },
    {
      title: "Dars -e- Nizami",
      description: "Explore the depths of Islamic knowledge through our Darse Nizami faculty, where tradition meets modernity, and students engage in a comprehensive study of Islamic theology, jurisprudence, and spirituality.    ",
      btnValue: "Read more",
    },
    {
      title: "Mahad ul Arabia",
      description: "Join the legacy of Islamic scholarship at our Mahad ul Arabia faculty, where aspiring scholars delve into advanced Arabic language and literature, equipping themselves to become torchbearers of Islamic wisdom.",
      btnValue: "Learn more",
    },
    {
      title: "Schooling System",
      description: " Discover an innovative and holistic approach to education within our Schooling System faculty, designed to nurture young minds with a strong foundation in both secular and Islamic studies, fostering well-rounded individuals ready to embrace the future.",
      btnValue: "View Details",
    },
  ];
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  return (
    <section className="lg:px-32 lg:py-24 sm:py-12 bg-gradient-to-r from-[#1d3c41] to-[#305357] ...">

      <div className="lg:px-32 lg:py-24 sm:py-12"
          style={{
            transform: isInView ? "none" : "translateX(-200px)",
            opacity: isInView ? 1 : 0,
            transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
          }}
          ref={ref}
        >
          <h2 className="font-[Poppins] lg:text-[32px] sm:text-2xl font-light text-[#57979e]">
            OUR FACULTIES
          </h2>
          <p className="font-[Poppins]  font-light text-[#57979e]">
            Embrace Global Perspectives: In a rapidly changing world, we equip
            you with the tools to bridge the gap between tradition and
            modernity.
          </p>
      </div>
        
      <div className=""
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
        }}
        ref={ref}
      >
        <div className="grid grid-cols-1 gap-10 lg:container lg:mx-auto md:grid-cols-2 lg:grid-cols-4">
          {data.map((value) => (
            <Box
              title={value.title}
              description={value.description}
              btnValue={value.btnValue}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
