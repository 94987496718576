export const NavData = [
  { text: "Faculties",
    href: "/Faculties",
    links: [
      {
        text: "Tahfeez ul Quran",
        href: "/Faculties#hifz",
      },
      {
        text: "Dars -e- Nizami",
        href: "/Faculties#dars",
      },
      {
        text: " Mahad ul Arabia",
        href: "/Faculties#mahad",
      },
      {
        text: "Schooling System",
        href: "/Faculties#school",
      },
    ],
  },
  { text: "About us", href: "/About" },  
  { text: "Contact us", href: "/Contact" },
  {
    text: "Admissions",
    href: "https://arabiaislamia.org/MadrasaLMS/OnlineAdmissionForm",
  },
  {
    text: "Qurbani Online",
    href: "https://www.arabiaislamia.org/OnlineQurbani/",
  },
];
