import { useEffect, useState } from "react";
import { Spinner } from "./Components/Spinner/Index";
import Navbar from "./Components/Navbar/Index";
import AnimatedRoutes from "./Components/AnimatedRoutes/index";
import Footer from "./Components/Footer/index";
import { BrowserRouter as Router } from "react-router-dom";
import { motion } from "framer-motion";

//export default function App() {
  function App() {
    const [timer, setTimer] = useState(true);
  
    useEffect(() => {
      setTimeout(() => {
        setTimer(false);
      }, 1600);
    }, []);
  
    return (
      <>
        {timer ? (
          <Spinner key={0} />
        ) : (
            <Router>
              <motion.div
                initial={{ width: 0 }}
                animate={{ width: "100%" }}
                exit={{ x: window.innerWidth }}>
                <Navbar />
                <AnimatedRoutes />
                <Footer />
              </motion.div>
            </Router>
        )}
      </>
    );
  }
  
  export default App;