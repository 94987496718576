import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./index.css";
// import StudentGroup from "../../Assets/students-group.jpg";
export default function index({
  ImageSource,
  imageSource2,
  ImageDesciption,
  style,
}) {
  return (
    <>
      <Carousel
        autoPlay="true"
        infiniteLoop="true"
        swipeable="false"
        showIndicators="true"
        interval={3000}
        width={"100%"}
        className="hidden md:block"
      >
        {/*
        <div className="flex items-end justify-center slider slider-bg-1">
          <div className="text-white lg:mb-24 sm:mb-2">
            <p className="text-5xl urdutext animate-pulse m-3">
              جامعہ عربیہ اسلامیہ <span className="text-xl">اسکاؤٹ کالونی</span>
            </p>

            <button className="relative px-12 py-2 overflow-hidden text-white transition-all bg-green-900 border-indigo-600 rounded-lg shadow-2xl lg:my-4 sm:my-6 lg:text-2xl sm:text-sm duration-50 transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-green-600 before:transition-all before:duration-50 hover:text-white hover:before:left-0 hover:before:w-full">
              <span className="relative z-10">
                <a href="https://arabiaislamia.org/MadrasaLMS/OnlineAdmissionForm">
                  Register now
                </a>{" "}
              </span>
            </button>
          </div>
        </div>
        */}
        <div className="flex items-end justify-center slider slider-bg-2">
          <div className="text-white lg:mb-24 sm:mb-2">
            <p className="text-5xl urdutext animate-pulse ">
              جامعہ عربیہ اسلامیہ <span className="text-xl">اسکاؤٹ کالونی</span>
            </p>
          </div>
        </div> 

        <div className="flex items-end justify-center slider slider-bg-3">
          <div className="text-white lg:mb-24 sm:mb-2">
            <p className="text-5xl urdutext animate-pulse ">
              جامعہ عربیہ اسلامیہ <span className="text-xl">اسکاؤٹ کالونی</span>
            </p>
          </div>
        </div> 

        <div className="flex items-end justify-center slider slider-bg-4">
          <div className="text-white lg:mb-24 sm:mb-2">
            <p className="text-5xl urdutext animate-pulse ">
              جامعہ عربیہ اسلامیہ <span className="text-xl">اسکاؤٹ کالونی</span>
            </p>
          </div>
        </div>
      </Carousel>

    </>
  );
}
